import React, { useCallback, useEffect } from 'react';
import t from '../../helpers/languages';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { debounce } from "lodash";
import { getAllProductCategories } from '../../services/productCategoriesService';

export default function FilterProductCategories({ category: initialProductCategory, getSelectedProductCategory }) {

  const [category, setProductCategory] = React.useState(0);
  const [categories, setProductCategories] = React.useState([]);
  const [openVessel, setOpenVessel] = React.useState(false);
  const [search, setSearch] = React.useState('');

  useEffect(() => {
    setSearch('');
    setProductCategory(initialProductCategory && initialProductCategory.id !== null ? initialProductCategory : 0);
    setProductCategories(initialProductCategory && initialProductCategory.id !== null ? [initialProductCategory] : []);
  }, [initialProductCategory]);



  const handleInputChange = (e, fetchType) => {
    const value = e && e.target && e.target.value;
    if (fetchType === "category" && value && value.length >= 3 ) {
      setSearch(value);
      debounceFn(value)
    };
  };

  const handleDebounceFn = (val) => {
    productCategorySearch({
      search: val
    })
  };
  const debounceFn = useCallback(debounce(handleDebounceFn, 800), []);

  const onProductCategorySelectionChange = (event, values) => {
    setProductCategory(values ? values : '');
    const selectedProductCategory = {
      category: values ? values : null,
    };
    getSelectedProductCategory(selectedProductCategory);
  };
  const productCategorySearch = (criteria) => {

    return getAllProductCategories(criteria).then((res) => {
      setProductCategories(res.data);
    }).catch((error) => {
    })
  }

  const randomNumber = (min, max) => {
    return Math.random() * (max - min) + min;
  };

  return (
    <div>
      <Autocomplete
        id={"find-category" + randomNumber(0, 999)}
        fullWidth
        open={openVessel}
        onOpen={() => {
          setOpenVessel(true);
        }}
        onClose={() => {
          setOpenVessel(false);
        }}
        onInputChange={e => handleInputChange(e, 'category')}
        onChange={onProductCategorySelectionChange}
        value={categories.find(v => v.id === category.id) || null}
        isOptionEqualToValue={(option, value) => (option.id === value.id)}
        getOptionLabel={(option) => (option.name !== null && option.length !== 0 ? option.name : option.description)}
        options={categories !== null ? categories : null}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('CATEGORY')}
            variant="outlined"
            InputProps={{
              ...params.InputProps
            }}
          />
        )}
      />
    </div>
  );
}