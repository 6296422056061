import React, { useCallback, useEffect } from 'react';
import t from '../../helpers/languages';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { debounce } from "lodash";
import { getAllContractRegions } from '../../services/originalRegionService';

export default function FilterContractRegions({ contractRegion: initialContractRegion, contract:initialContract, getSelectedContractRegion }) {

  const [contractRegion, setContractRegion] = React.useState(0);
  const [contractRegions, setContractRegions] = React.useState([]);
  const [openVessel, setOpenVessel] = React.useState(false);
  const [search, setSearch] = React.useState('');

  useEffect(() => {
    setContractRegion(initialContractRegion && initialContractRegion.id !== null ? initialContractRegion : 0);
    setContractRegions(initialContractRegion && initialContractRegion.id !== null ? [initialContractRegion] : []);
  }, [initialContractRegion]);

  const handleInputChange = (e,fetchType) => {
    const value = e && e.target && e.target.value;
    if (fetchType === "contractRegion" && value && value.length >= 2 && search !== value) {
      setSearch(value);
      debounceFn(value,initialContract)
    };
  };

  const handleDebounceFn = (val,initialContract) => {
    contractRegionSearch({
      search: val,
      contractId: initialContract?.id
    })
  };
  const debounceFn = useCallback(debounce(handleDebounceFn, 800), []);

  const randomNumber = (min, max) => {
    return Math.random() * (max - min) + min;
  }

  const onContractRegionSelectionChange = (event, values) => {
    setContractRegion(values ? values : '');
    const selectedContractRegion = {
      contractRegion: values ? values :  null, // return null when the value is empty string
    };
    getSelectedContractRegion(selectedContractRegion);
  };
  const contractRegionSearch = (criteria) => {

    return getAllContractRegions(criteria).then((res) => {
      setContractRegions(res.data);
    }).catch((error) => {
    })
  }

  return (
    <div>
      <Autocomplete
        id={"find-contractRegion" + randomNumber(0, 999)}
        fullWidth
        open={openVessel}
        onOpen={() => {
          setOpenVessel(true);
        }}
        onClose={() => {
          setOpenVessel(false);
        }}
        onInputChange={e => handleInputChange(e, 'contractRegion')}
        onChange={onContractRegionSelectionChange}
        value={contractRegions.find(v => v.id === contractRegion.id) || null}
        isOptionEqualToValue={(option, value) => (option.id === value.id)}
        getOptionLabel={(option) => (option.name !== null ? option.name : option.description)}
        options={contractRegions !== null ? contractRegions : null}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('ContractRegion')}
            variant="outlined"
            InputProps={{
              ...params.InputProps
            }}
          />
        )}
      />
    </div>
  );
}