
import React, { useCallback, useEffect } from 'react';
import t from '../../helpers/languages';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { debounce } from "lodash";
import { getAllContracts } from '../../services/contractsService';

export default function FilterContractsMultiple({ contract: initialContract, searchDate: initialSearchDate, getSelectedContract }) {

  const [contract, setContract] = React.useState(initialContract !== undefined ? initialContract : []);
  const [contracts, setContracts] = React.useState(initialContract !== undefined ? initialContract : []);
  const [openContract, setOpenContract] = React.useState(false);

  const [search, setSearch] = React.useState('');


  useEffect(() => {
    setContract(initialContract !== undefined ? initialContract : []);
    setContracts(initialContract !== undefined ? initialContract : []);
    const filterData = {
      searchDate: initialSearchDate
    };
    getAllContracts(filterData).then((res) => {
      setContracts(res.data);
    }).catch((error) => {
    })
  }, [initialContract]);



  const handleInputChange = (e, fetchType) => {
    const value = e && e.target && e.target.value;
    setSearch(value);
  };


  const onContractSelectionChange = (event, values) => {
    setContract(values ? values : '');
    const selectedContract = {
      contract: values ? values : '',
    };
    getSelectedContract(selectedContract);
  };


  return (
    <Autocomplete
      multiple
      id="find-contract-multiple"
      fullWidth
      open={openContract}
      //disabled={!initialSearchDate}
      onOpen={() => {
        setOpenContract(true);
      }}
      onClose={() => {
        setOpenContract(false);
      }}
      onInputChange={e => handleInputChange(e, 'contract')}
      onChange={onContractSelectionChange}
      value={contracts !== undefined ? contract : []}
      isOptionEqualToValue={(option, value) => (option.id === value.id)}
      getOptionLabel={(option) => (option.name)}
      options={contracts !== null && contracts.length !== 0 ? contracts : []}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('CONTRACT')}
          variant="outlined"
          InputProps={{
            ...params.InputProps
          }}
        />
      )}
    />
  );
}