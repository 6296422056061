import React from 'react';
import Vessels from '../vessels/Vessels';
import Users from '../users/Users';
import BuyerPreferences from '../buyerPreferences/BuyerPreferences';
import CompanyDictionaries from '../companyDictionaries/CompanyDictionaries';
import ProductDictionaries from '../productDictionaries/ProductDictionaries';
import PortDictionaries from '../portDictionaries/PortDictionaries';
import UnitDictionaries from '../unitDictionaries/UnitDictionaries';
import CountryDictionaries from '../countryDictionaries/CountryDictionaries';
import Pricelists from '../pricelists/Pricelists';
import RfidReaders from '../rfidReaders/RfidReaders';
import useCompanyHook from './useCompanyHook';
import useCompanyPreferenceHook from './useCompanyPreferenceHook';
import CompanyEditRow from './companyEditRow';
import CompanyPreferenceForm from './companyPreferenceForm';
import useStyles from "./styles";
import { TabPanel, a11yProps } from "../../components/tabPanel";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import { Paper } from '@mui/material';
import SimpleBreadcrumbs from '../../components/breadcrumbs';
import ImportFiles from '../../components/importFiles';


const CompanyInfo = (props) => {

  const classes = useStyles();
  const [value, setValue] = React.useState(0);


  const handleChange = (event, newValue) => {
	setValue(newValue);
  };

  const {
	getSelectedCodingSystem,
	getSelectedCountry,
	companiesData,
	from,
	to,
	getCompanyName,
	getCompanyAddress,
	getSelectedPort,
	getCompanyEmail,
	getSelectedCompanyCategory,
	getSupplierChecked,
	getIntegratedChecked,
	getUploadHistoricalData,
	getUploadPriceLists,
	getUploadProductsFile,
	getUploadUnitsFile,
	getUploadEquivalentProductsFile,
	getOnDateFromChange,
	getOnDateToChange
  } = useCompanyHook();

  const {
	prefsState,
	getPrefVal
  } = useCompanyPreferenceHook(props);

  const handleUploadHistoricalData = (data) => {
	getUploadHistoricalData(data);
  };

  const handleUploadPriceLists = (data) => {
	getUploadPriceLists(data);
  };

  const handleUploadProductsFile = (data) => {
	getUploadProductsFile(data);
  };

  const handleUploadEquivalentProductsFile = (data) => {
	getUploadEquivalentProductsFile(data);
  };

  const handleUploadUnitsFile = (data) => {
	getUploadUnitsFile(data);
  };

  const onDateFromChange = (data) => {
	getOnDateFromChange(data);
  };

  const onDateToChange = (data) => {
	getOnDateToChange(data);
  };

  return (
	<div className={classes.companyInfoWrapper}>
		<SimpleBreadcrumbs
			buttons={false}
			norfq={true}
		/>
		<Paper
			className={classes.paper}
			elevation={4}
		  >
			  <Grid
		  container
		  justifyContent="center"
		  alignItems="center"
		  direction="row"
		  spacing={3}>
		  <Grid item xs={12}>
			<div className={classes.root}>
			  <Tabs
				orientation="vertical"
				variant="scrollable"
				value={value}
				onChange={handleChange}
				aria-label="Vertical tabs example"
				className={classes.tabs}
			  >
				<Tab label="Uploads" value={0}  {...a11yProps(0)}>
				</Tab>
				<Tab label="Info" value={1}  {...a11yProps(1)}>
				</Tab>
				{!companiesData.companyEditObject.supplier && <Tab label="Vessels" value={2}  {...a11yProps(2)}>
				</Tab>}
				<Tab label="Users" value={3}  {...a11yProps(3)}>
				</Tab>
				{!companiesData.companyEditObject.supplier && <Tab label="Buyer Preferences" value={4}  {...a11yProps(4)}>
				</Tab>}
				 <Tab label="Preferences" value={5}  {...a11yProps(5)}>
				</Tab>
				<Tab label={companiesData.companyEditObject.supplier?'Buyers':'Suppliers'} value={6}  {...a11yProps(6)}>
				</Tab>
				<Tab label="Units" value={7}  {...a11yProps(7)}>
				</Tab>
				<Tab label="Products" value={8}  {...a11yProps(8)}>
				</Tab>
				<Tab label="Ports" value={9}  {...a11yProps(9)}>
				</Tab>
				<Tab label="Countries" value={10}  {...a11yProps(10)}>
				</Tab>
				{companiesData.companyEditObject.supplier &&<Tab label="Pricelists" value={11}  {...a11yProps(10)}>
				</Tab>}
				{!companiesData.companyEditObject.supplier && <Tab label="Rfid Readers" value={12}  {...a11yProps(4)}>
				</Tab>}
			  </Tabs>
		      <TabPanel value={value} index={0}>
			       <ImportFiles
						supplier={companiesData.companyEditObject.supplier}
						handleUploadHistoricalData={handleUploadHistoricalData}
						handleUploadPriceLists={handleUploadPriceLists}
						handleUploadProductsFile={handleUploadProductsFile}
						handleUploadUnitsFile={handleUploadUnitsFile}
						handleUploadEquivalentProductsFile={handleUploadEquivalentProductsFile}
						onDateFromChange={onDateFromChange}
						onDateToChange={onDateToChange}
						from={from}
						to={to}
					/>
			</TabPanel>
			  <TabPanel value={value} index={1}>
				<CompanyEditRow
				  getSelectedCodingSystem={getSelectedCodingSystem}
				  companiesData={companiesData}
				  getCompanyName={getCompanyName}
				  getSelectedCountry={getSelectedCountry}
				  getCompanyAddress={getCompanyAddress}
				  getCompanyEmail={getCompanyEmail}
				  getSelectedPort={getSelectedPort}
				  getSelectedCompanyCategory={getSelectedCompanyCategory}
				  getSupplierChecked={getSupplierChecked}
				  getIntegratedChecked={getIntegratedChecked}
				/>
			  </TabPanel>
			  {!companiesData.companyEditObject.supplier && <TabPanel value={value} index={2}>
				<Vessels 
				userState={props.userState} 
				pageFilter={true}
				autonomousPage={false} 
				/>
			  </TabPanel>}
			  <TabPanel value={value} index={3}>
				<Users 
				  userState={props.userState}
				  pageFilter={true}
				  autonomousPage={false}
				/>
			  </TabPanel>
			  {!companiesData.companyEditObject.supplier && <TabPanel value={value} index={4}>
				<BuyerPreferences 
				userState={props.userState} 
				pageFilter={true}
				autonomousPage={false}
				companyId={companiesData.companyEditObject.id}
				/>
			  </TabPanel>}
			  <TabPanel value={value} index={5} >
			  <CompanyPreferenceForm prefsState={prefsState} getPrefVal={getPrefVal} /> 
			  </TabPanel>
			  <TabPanel value={value} index={6}>
				<CompanyDictionaries 
				  userState={props.userState}
				  companyId={companiesData.companyEditObject.id}
				  supplier={companiesData.companyEditObject.supplier}
				  pageFilter={true} 
				  autonomousPage={false}
				/>
			  </TabPanel>
			  <TabPanel value={value} index={7}>
				<UnitDictionaries 
				  userState={props.userState}
				  companyId={companiesData.companyEditObject.id}
				  supplier={companiesData.companyEditObject.supplier}
				  pageFilter={true}
				  autonomousPage={false} 
				/>
			  </TabPanel>
			  <TabPanel value={value} index={8}>
				<ProductDictionaries 
				  userState={props.userState}
				  companyId={companiesData.companyEditObject.id}
				  supplier={companiesData.companyEditObject.supplier}
				  pageFilter={true}
				  autonomousPage={false} 
				/>
			  </TabPanel>
			  <TabPanel value={value} index={9}>
				<PortDictionaries 
				  userState={props.userState}
				  companyId={companiesData.companyEditObject.id}
				  supplier={companiesData.companyEditObject.supplier}
				  pageFilter={true}
				  autonomousPage={false} 
				/>
			  </TabPanel>
			  <TabPanel value={value} index={10}>
				<CountryDictionaries 
				  userState={props.userState}
				  companyId={companiesData.companyEditObject.id}
				  supplier={companiesData.companyEditObject.supplier}
				  pageFilter={true}
				  autonomousPage={false} 
				/>
			  </TabPanel>
			  {companiesData.companyEditObject.supplier &&<TabPanel value={value} index={11}>
				<Pricelists 
				  userState={props.userState}
				  companyId={companiesData.companyEditObject.id}
				  pageFilter={true}
				  autonomousPage={false} 
				/>
			  </TabPanel>}
			  {!companiesData.companyEditObject.supplier && <TabPanel value={value} index={12}>
				<RfidReaders
				userState={props.userState} 
				pageFilter={true}
				autonomousPage={false}
				companyId={companiesData.companyEditObject.id}
				/>
			  </TabPanel>}
			</div>
		  </Grid>
		</Grid>
	  </Paper>
	</div >


  );
}

export default CompanyInfo;