import httpService from './httpService';

const getAllRFIDReaders = (criteria = {}) => {
  if (!criteria.search) {
    delete criteria.search;
  }
  return httpService.FetchVesselRFIDConfigs(criteria);
};

const deleteRFIDReader = (criteria = {}) => {
  return httpService.DeleteVesselRFIDConfig(criteria);
};


const editRFIDReader = (id, data) => {
  return httpService.UpdateVesselRFIDConfig({}, data);
};

const addRFIDReader = (data) => {
  return httpService.CreateVesselRFIDConfig({}, data);
};


export { getAllRFIDReaders, deleteRFIDReader, editRFIDReader, addRFIDReader };
