
const tableHeadersPortRegions = [
  { id: 'country-name', disablePadding: false, label: 'Country' },
  { id: 'name', disablePadding: false, label: 'Port' },
  { id: 'actions', disablePadding: false, label: 'Actions' },
];

// Empty port region
const newPortRegion = () => {
  return {
    id: '?',
    name: '',
    country: { name: '', id: '' },
  };

};

export { tableHeadersPortRegions, newPortRegion  };