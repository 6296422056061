import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from "@mui/material/TextField";
import FormControl from '@mui/material/FormControl';
import FilterVessels from "../../components/filterVessels";
import FilterUsers from "../../components/filterUsers";
import React, { useEffect, useState, useRef } from 'react';
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import TableHeader from '../../components/tableHeader';
import { localStoreSetObj, localStoreGetObj } from '../../helpers/storage';
import { tableHeadersRfidReader, newRfidReader } from '../../helpers/rfidReaderConfig';
import { RfidReadersTableFilter } from '../../components/rfidReadersTableFilter';
import { urlParams } from "../../helpers/urlParams";
import useStyles from './styles';
import TableActions from '../../components/TableActions/TableActions';
import SimpleReactValidator from 'simple-react-validator';
import { getAllRFIDReaders, deleteRFIDReader, editRFIDReader, addRFIDReader } from '../../services/rfidReadersService';
import './_rfidReaders.scss';
import t from '../../helpers/languages';

const RfidReaders = (props) => {

  const rfidReaderInitObject = () => {
    return {
      id: '?',
      active: null,
      vessel: { id: null },
      authAccount: { id: null },
      authPassInput: null,
      rfidReaderSerial: null,
      rfidReaderIp: null
    }
  };
  const buyerObj = { id: props.companyId };

  const classes = useStyles();

  // const userState = useSelector((state) => {
  //   return {
  //     role: state.users.user.role,
  //     company: state.users.user.company,
  //   };
  // });

  const filters = null;
  //const filters = localStoreGetObj('rfidReaderFilter');

  const [rfidReadersState, setRfidReadersState] = useState({
    rfidReaders: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      order_type: 'asc',
      order_by: 'id'
    },
    filters: {},
    isLoading: true,
    rfidReaderEditIndex: -1,
    rfidReaderEditObject: rfidReaderInitObject(),
    rfidReaderCreateObject: null,
    error: null,
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    }
  });

  const criteria = urlParams.getUrlParams();
  !criteria.per_page && (criteria.per_page = 10)
  !criteria.page && (criteria.page = 1)

  urlParams.setUrlParams(criteria);

  let filtersData = {};


  filtersData = {
    ...rfidReadersState.filters,
    search: filters && filters.search,
    companyId: props.companyId,
    per_page: criteria.per_page,
    page: criteria.page,
  };


  useEffect(function () {
    getAllRFIDReaders(filtersData).then(res => {
      const { results } = res.data;

      setRfidReadersState({
        ...rfidReadersState,
        rfidReaders: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setRfidReadersState({
        ...rfidReadersState,
        isLoading: false,
      });
    });
    // eslint-disable-next-line  
  }, []);

  const handleChangePage = (event, newPage) => {
    setRfidReadersState({
      ...rfidReadersState,
      isLoading: true
    });
    const newCriteria = {
      ...rfidReadersState.filters,
      per_page: rfidReadersState.page.per_page,
      page: newPage + 1
    };
    getAllRFIDReaders(newCriteria).then(res => {
      setRfidReadersState({
        ...rfidReadersState,
        isLoading: false,
        rfidReaders: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams(res.data.page);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    //na mpei to company id gia mba member
    setRfidReadersState({
      ...rfidReadersState,
      isLoading: true
    });
    const perPage = parseInt(event.target.value, 10);
    const newCriteria = {
      ...rfidReadersState.filters,
      page: 1,
      per_page: perPage,
    };

    getAllRFIDReaders(newCriteria).then(res => {
      setRfidReadersState({
        ...rfidReadersState,
        isLoading: false,
        rfidReaders: res.data.results,
        page: res.data.page,
        criteria: newCriteria
      })
      urlParams.setUrlParams(newCriteria);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setRfidReadersState({
        ...rfidReadersState,
        isLoading: false,
      });
    });
  };

  const handleEditRfidReader = (event, rfidReaderIndex) => {

    event.stopPropagation();

    const rfidReaderEditObject = Object.assign({}, rfidReadersState.rfidReaders[rfidReaderIndex]);


    setRfidReadersState({
      ...rfidReadersState,
      rfidReaderEditIndex: rfidReaderIndex,
      rfidReaderEditObject: rfidReaderEditObject
    });

  };

  const onEditableRfidReaderChange = (event, property) => {

    const newValue = event.target.value;

    const newEditRfidItem = Object.assign({}, rfidReadersState.rfidReaderEditObject);
    newEditRfidItem[property] = newValue;

    setRfidReadersState({
      ...rfidReadersState,
      rfidReaderEditObject: newEditRfidItem
    });
  };
  const [, forceUpdate] = useState();
  //todo
  const validator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate }, showMessages: true }));


  const handleSaveRfidReader = (event, props) => {
    event.preventDefault();
    setRfidReadersState({
      ...rfidReadersState,
      isLoading: true
    });
    const newEditRfidItem = Object.assign({}, rfidReadersState.rfidReaderEditObject);

    if (validator.current.allValid()) {
      editRFIDReader(newEditRfidItem.id, newEditRfidItem)
        .then(re => {
          const editedRfid = re.data;
          setRfidReadersState(oldRfidReaders => {
            return {
              ...oldRfidReaders,
              rfidReaders: oldRfidReaders.rfidReaders.map(rfidReader => {
                return rfidReader.id === editedRfid.id
                  ? re.data : rfidReader
              }),
              rfidReaderEditIndex: -1
            }
          });
        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setRfidReadersState({
            ...rfidReadersState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
      setRfidReadersState({
        ...rfidReadersState,
        isLoading: false,
      });
    }

  };

  const handleCloseEditRfidReader = (e, index) => {
    setRfidReadersState({
      ...rfidReadersState,
      rfidReaderEditIndex: -1
    })
  };

  const handleDeleteRfidReader = (event, index) => {

    const rfidReaderForDeletion = rfidReadersState.rfidReaders[index];
    setRfidReadersState({
      ...rfidReadersState,
      confirmDialog: {
        message: 'Are you sure you want to delete?',
        title: 'Delete Rfid Reader',
        onCancel: () => {
          setRfidReadersState({
            ...rfidReadersState,
            confirmDialog: {
              ...rfidReadersState.confirmDialog,
              isOpen: false
            }
          })
        },
        onConfirm: () => {
          deleteRFIDReader(rfidReaderForDeletion.id).then(() => {
            const newRfidReaders = [...rfidReadersState.rfidReaders];
            newRfidReaders.splice(index, 1);
            setRfidReadersState({
              ...rfidReadersState,
              rfidReaders: newRfidReaders,
            });
          })
            .catch(err => {
              alert('ERROR')
            });
        },
        isLoading: false,
        isOpen: true
      }
    });
  };

  const handleAddNewRfidReader = (e) => {
    e.preventDefault();

    setRfidReadersState({
      ...rfidReadersState,
      rfidReaderCreateObject: newRfidReader()
    });
    setTimeout(() => {
      document.querySelector("#root").scrollTo(0, 500);
    }, 0);
  };

  const onSaveNewRfidReader = () => {
    //TODO Remaining to resolve HTTP - 400 bad request

    let newRfidReader = {};
    newRfidReader.vessel = rfidReadersState.rfidReaderCreateObject.vessel;
    newRfidReader.authAccount = rfidReadersState.rfidReaderCreateObject.authAccount;
    newRfidReader.authPassInput = rfidReadersState.rfidReaderCreateObject.authPassInput;
    newRfidReader.rfidReaderSerial = rfidReadersState.rfidReaderCreateObject.rfidReaderSerial;
    newRfidReader.rfidReaderIp = rfidReadersState.rfidReaderCreateObject.rfidReaderIp;

    if (validator.current.allValid()) {
      addRFIDReader(newRfidReader)
        .then(res => {
          setRfidReadersState({
            ...rfidReadersState,
            rfidReaders: [...rfidReadersState.rfidReaders, res.data],
            rfidReaderCreateObject: null,
            rfidReaderEditIndex: -1,
            isLoading: false,
          })

        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setRfidReadersState({
            ...rfidReadersState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
    }
  };

  const selectedVessel = (data, action) => {

    const newEditRfidReaderItem = Object.assign({},
      action === 'edit'
        ? rfidReadersState.rfidReaderEditObject
        : rfidReadersState.rfidReaderCreateObject
    );

    newEditRfidReaderItem.vessel = data.vessel;

    setRfidReadersState({
      ...rfidReadersState,
      [
        action === 'edit'
          ? 'rfidReaderEditObject'
          : 'rfidReaderCreateObject'
      ]: newEditRfidReaderItem
    });
  };


  const selectedUser = (data, action) => {

    const newEditRfidReaderItem = Object.assign({},
      action === 'edit'
        ? rfidReadersState.rfidReaderEditObject
        : rfidReadersState.rfidReaderCreateObject
    );

    newEditRfidReaderItem.authAccount = data.user;

    setRfidReadersState({
      ...rfidReadersState,
      [
        action === 'edit'
          ? 'rfidReaderEditObject'
          : 'rfidReaderCreateObject'
      ]: newEditRfidReaderItem
    });
  };

  const onEditNewRfidReaderObject = (e, property) => {

    const newValue = e.target.value;

    const newEditRfidReaderItem = Object.assign({}, rfidReadersState.rfidReaderCreateObject);
    newEditRfidReaderItem[property] = newValue;

    setRfidReadersState({
      ...rfidReadersState,
      rfidReaderCreateObject: newEditRfidReaderItem
    });
  };

  const filterData = (data) => {
    setRfidReadersState({
      ...rfidReadersState,
      isLoading: true
    });

    let filtersData = '';

    filtersData = {
      ...rfidReadersState.filters,
      search: data && data.search,
      vesselId: data && data.vessel?.id,
    };

    getAllRFIDReaders(filtersData).then(res => {
      const { results } = res.data;

      setRfidReadersState({
        ...rfidReadersState,
        rfidReaders: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      localStoreSetObj('rfidReaderFilter', data);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setRfidReadersState({
        ...rfidReadersState,
        isLoading: false,
      });
    });

  };

  const tableFilterComponent = () => {
    return <RfidReadersTableFilter
      search={(filters && filters.search) ? filters.search : rfidReadersState.filters.search}
      vessel={(filters && filters.vessel) ? filters.vessel : rfidReadersState.filters.vessel}
      companyId={props.companyId}
      getFilterData={filterData}
    />
  };

  const tableHeaderComponent = () => {
    return <TableHeader
      headCells={tableHeadersRfidReader}
      onSelectAllClick={() => { }}
      sort={false}
      order={''}
      orderBy={''}
      rowCount={''} />;
  };


  const tableBodyComponent = () => {
    return (
      <TableBody>
        {Array.isArray(rfidReadersState.rfidReaders) && rfidReadersState.rfidReaders.map((rfidReader, index) => {
          return (
            <TableRow
              key={index}
              className={classes.tableRow}
            >{
                (rfidReadersState.rfidReaderEditIndex !== index) && index !== '?'
                  ? <>
                    <TableCell>{rfidReader.rfidReaderSerial}</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>{rfidReader.authAccount.userName}</TableCell>
                    <TableCell>{rfidReader.vessel.name}</TableCell>
                  </> :
                  <>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={rfidReadersState.rfidReaderEditObject.rfidReaderSerial || ''}
                          onChange={e => onEditableRfidReaderChange(e, 'rfidReaderSerial')}
                        />
                      </FormControl>
                      {validator.current.message('rfidReaderSerial', rfidReadersState.rfidReaderEditObject.rfidReaderSerial, 'required')}
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={rfidReadersState.rfidReaderEditObject.authPassInput || ''}
                          onChange={e => onEditableRfidReaderChange(e, 'authPassInput')}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FilterUsers
                          user={rfidReadersState.rfidReaderEditObject.authAccount || null}
                          getSelectedUser={(data) => selectedUser(data, 'edit')}
                        />
                        {validator.current.message('company user', rfidReadersState.rfidReaderEditObject.authAccount?.id, 'required|numeric')}
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FilterVessels
                          company={buyerObj}
                          vessel={rfidReadersState.rfidReaderEditObject.vessel || null}
                          getSelectedVessel={(data) => selectedVessel(data, 'edit')}
                        />
                        {validator.current.message('vessel', rfidReadersState.rfidReaderEditObject.vessel?.id, 'required|numeric')}
                      </FormControl>
                    </TableCell>

                  </>}
              <TableCell>
                <TableActions config={{
                  edit: {
                    enabled: rfidReadersState.rfidReaderEditIndex !== index,
                    callback: (e) => handleEditRfidReader(e, index)
                  },
                  delete: {
                    enabled: rfidReadersState.rfidReaderEditIndex !== index,
                    callback: (e) => handleDeleteRfidReader(e, index)
                  },
                  close: {
                    enabled: rfidReadersState.rfidReaderEditIndex === index,
                    callback: (e) => handleCloseEditRfidReader(e, index)
                  },
                  save: {
                    enabled: rfidReadersState.rfidReaderEditIndex === index,
                    callback: (e) => handleSaveRfidReader(e, props)
                  }
                }} />
              </TableCell>
            </TableRow>
          );
        })}{
          rfidReadersState.rfidReaderCreateObject &&
          <TableRow
            className={classes.tableRow}
          >
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  value={rfidReadersState.rfidReaderCreateObject.rfidReaderSerial || ''}
                  onChange={e => onEditNewRfidReaderObject(e, 'rfidReaderSerial')}
                />
                {validator.current.message('rfidReaderSerial', rfidReadersState.rfidReaderCreateObject.rfidReaderSerial, 'required')}
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  value={rfidReadersState.rfidReaderCreateObject.authPassInput || ''}
                  onChange={e => onEditNewRfidReaderObject(e, 'authPassInput')}
                />
                {validator.current.message('password', rfidReadersState.rfidReaderCreateObject.authPassInput, 'required')}
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <FilterUsers
                  user={rfidReadersState.rfidReaderCreateObject.authAccount || null}
                  getSelectedUser={(data) => selectedUser(data, 'create')}
                />
                {validator.current.message('company user', rfidReadersState.rfidReaderCreateObject.authAccount?.id, 'required|numeric')}
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <FilterVessels
                  company={buyerObj}
                  vessel={rfidReadersState.rfidReaderCreateObject?.vessel || null}
                  getSelectedVessel={(data) => selectedVessel(data, 'create')}
                />
                {validator.current.message('vessel', rfidReadersState.rfidReaderCreateObject.vessel?.id, 'required|numeric')}
              </FormControl>
            </TableCell>
            <TableCell>
              <TableActions config={{
                edit: {
                  enabled: false,
                  callback: () => null
                },
                delete: {
                  enabled: false,
                  callback: () => null
                },
                close: {
                  enabled: true,
                  callback: (e) => setRfidReadersState({ ...rfidReadersState, rfidReaderCreateObject: null })
                },
                save: {
                  enabled: true,
                  callback: (e) => onSaveNewRfidReader()
                }
              }}>
              </TableActions>
            </TableCell>
          </TableRow>
        }
      </TableBody>
    );
  }
  return (
    <>
      <ProcureDataTable
        toolbarTitle={t('RFID_READERS')}
        pagination={{
          total: rfidReadersState.page.total || 0,
          page: rfidReadersState.page.page - 1 || 0,
          perPage: rfidReadersState.page.per_page || 10,
          count: rfidReadersState.page.count || 0,
          handleChangePage: handleChangePage,
          handleChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        addNewRawBtnTitle={''}
        newRowActionAvailable={true}
        onNewRowClick={handleAddNewRfidReader}
        newRowBtnEnabled={!rfidReadersState.rfidReaderCreateObject}
        confirmDialog={rfidReadersState.confirmDialog.message.length > 0 ? rfidReadersState.confirmDialog : rfidReadersState.confirmDialog}
        tableHeader={tableHeaderComponent}
        tableBody={tableBodyComponent}
        tableFilter={tableFilterComponent}
        autonomousPage={props.autonomousPage}
        isLoading={rfidReadersState.isLoading}
      />
    </>
  );
};


export default RfidReaders;
