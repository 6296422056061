
const tableHeaders = [

  { id: 'name', disablePadding: false, label: 'Name' },
  { id: 'contractName', disablePadding: false, label: 'Contract' },
  { id: 'dateFrom', disablePadding: false, label: 'Date From' },
  { id: 'dateTo', disablePadding: false, label: 'Date To' },
  { id: 'actions', disablePadding: false, label: 'Actions' },
];

// Empty pricelist
const newContract = () => {
  return {
    id: '?',
    name: '',
    contract: { name: '', id: '',dateFrom:'',dateTo:'' },
  };
};

export { tableHeaders, newContract };
