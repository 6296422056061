import React, { useState, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import TextField from "@mui/material/TextField";
import { Grid,debounce,  } from "@mui/material";
import FilterVessels from "../filterVessels";
import './_rfidReadersTableFilter.scss';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing('1px'),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing('2px'),
  },
}));

export const RfidReadersTableFilter = ({
  companyId: initialCompanyId,
  vessel: initialVessel,
  search: initialSearch,
  getFilterData
}) => {
  const classes = useStyles();

  const [vessel, setVessel] = useState(initialVessel);
  const [search, setSearch] = useState(initialSearch || '');

  const selectedVessel = (data) => {
    setVessel(data.vessel);
    const filterData = {
      search: search,
      vessel: data.vessel,

    };
    getFilterData(filterData);
  }


  const handleChange = (event, property) => {
      setSearch(event.target.value);
      const filterData = {
        vessel: vessel,
        search: event.target.value
      };
      debounceFn(filterData);
  };

  const handleDebounceFn = (val) => {
    getFilterData(val)
  };
  const debounceFn = useCallback(debounce(handleDebounceFn, 800), []);


  const companyObj={id:initialCompanyId};

  return (
    <form>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={2} >
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <FilterVessels
              vessel={vessel}
              company={companyObj}
              getSelectedVessel={selectedVessel} />
          </FormControl>
        </Grid>
        <Grid item xs={2} >
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <TextField
              value={search}
              variant="outlined" className={classes.formControl}
              onChange={e => handleChange(e, 'search')}
              name="search"
              label="Serial"
            />
          </FormControl>
        </Grid>
      </Grid >
    </form >
  );
}