const en = {
  ACCOUNT_SETTINGS: 'Account Settings',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_PRODUCT_WITH_ID: 'Are you sure you want to delete the product with id',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_ALL_THOSE_PRODUCTS: 'Are you sure you want to delete all those products',
  BUYS: 'Buys',
  BUYERS: 'Buyers',
  CANCEL: 'Cancel',
  CREATE_NEW_PRODUCT: 'Create New Product',
  CREATE_EDIT_PRODUCT_FORM_NAME_HINT_MESSAGE: '*Enter the name of the product',
  CREATE_EDIT_PRODUCT_FORM_NOTES_HINT_MESSAGE: 'Enter your notes about the product',
  CREATE_EDIT_PRODUCT_FORM_RECIPIENT_EMAIL_HINT_MESSAGE: '*Enter the recipient\'s email of the product',
  CREATE_EDIT_PRODUCT_FORM_NAME_ERROR_MESSAGE_REQUIRED: 'The name of the product is required',
  CREATE_PRODUCT_DIALOG: 'Create Product Dialog',
  DELETE_PRODUCT: 'Delete Product',
  DELETE_PRODUCTS: 'Delete Products',
  DELETE_PRODUCT_CONFIRMATION_DIALOG: 'delete-product-confirmation-dialog',
  DELIVERIES: 'Deliveries',
  EDIT_PRODUCT: 'Edit product',
  EDIT_PRODUCT_DIALOG: 'Edit Product Dialog',
  EDIT_PRODUCT_WITH_ID: 'Edit product with id',
  EFFECTIVENESS: 'Effectiveness',
  EVALUATIONS: 'Evaluations',
  VESSEL: 'Vessel',
  VESSELS: 'Vessels',
  EXPAND_REPORTS: 'Expand Reports',
  HOME: 'Home',
  LOGIN_TITLE_TEXT_1: 'Sea-Beyond',
  LOGIN_TITLE_TEXT_2: '',
  LOGIN_TITLE_TEXT_3: '',
  LOGIN_TITLE_SMALL_TEXT: 'Procurement & Contract Management',
  LOGIN_FORM_USER_NAME_LABEL: 'UserName',
  LOGIN_FORM_USER_NAME: 'userName',
  LOGIN_FORM_USER_NAME_HINT_MESSAGE: '*Enter your username',
  LOGIN_FORM_USER_NAME_ERROR_MESSAGE_REQUIRED: 'Username is required',
  LOGIN_FORM_PASSWORD_LABEL: 'Password',
  LOGIN_FORM_PASSWORD_NAME: 'password',
  LOGIN_FORM_PASSWORD_HINT_MESSAGE: '*Enter your password',
  LOGIN_FORM_PASSWORD_ERROR_MESSAGE_REQUIRED: 'Password is required',
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  OK: 'Ok',
  PAGE_TITLE: 'sea-beyond',
  PERFORMANCE: 'Performance',
  PORTS: 'Ports',
  PRODUCTS: 'Products',
  PRODUCTS_TABLE: 'Products table',
  PRODUCTS_TABLE_CHECKBOX_ID: 'products-table-checkbox',
  REQUESTS: 'Requests',
  SALES: 'Sales',
  SELECTED: 'Selected',
  SELECT_ALL_PRODUCTS: 'Select all products',
  SHRINK_REPORTS: 'Shrink Reports',
  SIDENAV_LINK_TEXT_DASHBOARD: 'Dashboard',
  SIDENAV_LINK_TEXT_PRODUCTS: 'Products',
  SORTED_ASCENDING: 'Sorted ascending',
  SORTED_DESCENDING: 'Sorted descending',
  SUPPLIERS: 'Suppliers',
  USERS: 'Users',
  UTILS: 'Utils',
  WELCOME_USER: 'Welcome',
  PORT_ALLOCATION: 'Port Allocation',
  VESSEL_ALLOCATION: 'Vessel Allocation',
  OPEX: 'OpEx',
  QUOTES: 'Quotes',
  QUOTES_TABLE: 'Quotes table',
  QUOTES_TABLE_CHECKBOX_ID: 'Quotes table checkbox id',
  EDIT_QUOTE: 'Edit Quote',
  EDIT_QUOTE_WITH_ID: 'Edit Quote with id',
  CREATE_NEW_QUOTE: 'Create new Quote',
  DELETE_QUOTE_CONFIRMATION_DIALOG: 'Delete Quote confirmation dialog',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_QUOTE_WITH_ID: 'Are you sure you want to delete the Quote with id',
  RFQS: 'RFQ\'s',
  RFQS_TABLE: 'Rfqs table',
  RFQS_TABLE_CHECKBOX_ID: 'Rfqs table checkbox id',
  EDIT_RFQ: 'Edit Rfq',
  EDIT_RFQ_WITH_ID: 'Edit Rfq with id',
  CREATE_NEW_RFQ: 'Create new Rfq',
  DELETE_RFQ_CONFIRMATION_DIALOG: 'Delete Rfq confirmation dialog',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_RFQ_WITH_ID: 'Are you sure you want to delete the Rfq with id',
  ORDERS: 'Orders',
  ORDERS_TABLE: 'Orders table',
  ORDERS_TABLE_CHECKBOX_ID: 'Orders table checkbox id',
  EDIT_ORDER: 'Edit Order',
  EDIT_ORDER_WITH_ID: 'Edit Order with id',
  CREATE_NEW_ORDER: 'Create new Order',
  DELETE_ORDER_CONFIRMATION_DIALOG: 'Delete Order confirmation dialog',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_ORDER_WITH_ID: 'Are you sure you want to delete the Order with id',
  PORT: 'Port',
  CATEGORY: 'Category',
  FROM: 'From',
  TO: 'To',
  CUSTOMER: 'Customer',
  COMPANY: 'Company',
  RFQ_ITEMS: 'Rfq Items',
  RFQS_ITEMS_TABLE: 'Rfq Items Table',
  RFQS_ITEMS_TABLE_CHECKBOX_ID: 'Rfqs Items table checkbox id',
  VIEW_RFQ: 'View Rfq',
  VIEW_RFQ_ID: 'View Rfq id',
  SUPPLIER: 'Supplier',
  CATALOGUE: 'Catalogue',
  DUE: 'Due',
  STATUS: 'Status',
  QUOTATION: 'Quotation',
  QUOTE_ITEMS: 'Quote Items',
  QUOTE_ITEMS_TABLE: 'Quote Items table',
  QUOTE_ITEMS_TABLE_CHECKBOX_ID: 'Quote Items table checkbox id',
  VIEW_QUOTE_ID: 'View Quote id',
  VIEW_QUOTE: 'View Quote',
  DELETE_QUOTE_ITEM_CONFIRMATION_DIALOG: 'Delete Quote Item Confirmation Dialog',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_QUOTE_ITEM_WITH_ID: 'Are you Sure you Want to Delete the Quote Item With id?',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_ALL_THOSE_QUOTE_ITEMS: 'Are you Sure you Want to Delete all Those Quote Items',
  DELETE_QUOTE_ITEM: 'Delete Quote Item',
  DELETE_QUOTE_ITEMS: 'Delete Quote Items',
  CANCEL_ORDER: 'Cancel Order',
  CANCEL_ORDER_WITH_ID: 'Cancel Order with id',
  CONFIRM_ORDER: 'Confirm Order',
  CONFIRM_ORDER_WITH_ID: 'Confirm Order with id',
  PRODUCT: 'Product',
  CREATE_NEW_RFQ_ITEM: 'Create new Rfq item',
  UNIT: 'Unit',
  COMPLETED: 'Completed',
  VIEW_ORDER: 'View Order',
  VIEW_TRANSACTION: 'View Transaction',
  ADD_SUPPLIER: 'Add Supplier',
  ADD_SUPPLIER_WITH_ID: 'Add Supplier with id',
  CURRENCY: 'Currency',
  ITEMS: 'Items',
  REQUEST_FOR_QUOTATION: 'Request for Quotation',
  NOTES: 'Notes',
  COMPANIES: 'Companies',
  REFERENCE_NUMBER: 'Reference Number',
  TRANSACTIONS: 'Transactions',
  FIRST_NAME: 'Fisrt Name',
  LAST_NAME: 'Last Name',
  USER_NAME: 'AccountSettings Name',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  SUBMIT: 'Submit',
  ORDER: 'Order',
  SUBMITTED: 'Submitted',
  TOTAL_COST: 'Total Cost',
  BUYER_PREFERENCES: 'Buyer Preferences',
  BUYER: 'Buyer',
  CODING: 'Coding',
  YES: 'Yes',
  NO: 'No',
  NAME: 'Name',
  TELEPHONE: 'Telephone',
  AGENT_NAME: 'Agent Name',
  AGENT_TELEPHONE: 'Agent Telephone',
  DATA_AUDIT: 'Data Audit',
  PRODUCT_RESOLUTION: 'Product Resolution',
  PORT_RESOLUTION: 'Port Resolution',
  UNIT_RESOLUTION: 'Unit Resolution',
  MATCH: 'Match',
  SEARCH: 'Search',
  PORT_MATCH: 'Port Match',
  PORT_LIST: 'Port List',
  UΝIΤ_LIST: 'Unit List',
  UNIT_MATCH: 'Unit Match',
  PRODUCT_LIST: 'Product List',
  PRODUCT_MATCH: 'Product Match',
  ERPTYPE: 'Erp Type',
  FSPROTOCOLTYPE: 'FS Protocol Type',
  TRANSACTION:'Transaction',
  PRINT:'Print',
  EXPORT:'Export',
  DISCOUNT: 'Discount',
  TOP_10_CONSUMPTION_PORTS:'Top 10 consumption ports',
  TOP_10_CONSUMPTION_VESSELS:'Top 10 consumption vessels',
  COUNTRY_RESOLUTION:'Country Resolution',
  COUNTRIES:'Countries',
  MATCH_LIST:'Match & List',
  COUNTRY_LIST:'Country List',
  COUNTRY_MATCH:'Country Match',
  EVALUATION:'Evaluation',
  PRODUCT_EVALUATION:'Product Evaluation',
  SERVICE_EVALUATION:'Service Evaluation',
  OVERALL_QUALITY:'Overall Quality',
  ACCORDING_TO_SPECIFICATIONS:'According to Specifications',
  INSTRUCTIONS_INCLUDED_ENGLISH:'Instructions included (English)',
  CERTIFICATES_INCLUDED_WHERE_NEEDED:'Certificates included (where needed)',
  PACKING_ENVIRONMENT_FRIENDLY:'Packing - Environment Friendly',
  GENERAL_IMPRESSION:'General Impression',
  FRIENDLINESS:'Friendliness',
  RESPONSIVENESS:'Responsiveness',
  DELIVERY_WITHIN_TIME_SCHEDULE:'Delivery within Time Schedule',
  GENERAL_NOTES:'General Notes',
  SERVICES: 'Services',
  AGENT_ADDRESS: 'Agent Address',
  AGENT_EMAIL:'Agent Email',
  AGENT_FAX:'Agent Fax',
  COUNTRY:'Country',
  PREFERENCES: 'Preferences',
  TYPE: 'Type',
  SUPPLIER_REFERENCE: 'Supplier Reference',
  SUPPLIER_NOTES: 'Supplier Notes',
  DECLINE_NOTES: 'Decline Notes',
  ALERT:'Alert',
  UPDATE_ORDER_STATUS:'Update Order Status',
  ORDER_STATUS_NOTES:'Order Status Notes',
  COM_AUDIT:'Com Audit',
  LOGS:'Logs',
  TOP_10_SPENDING_PORTS:'Top 10 spending ports',
  TOP_10_SPENDING_VESSELS:'Top 10 spending vessels',
  IMPORT:'Import',
  UNIT_LIST: 'Unit List',
  EDIT_ITEMS:'EDIT ITEMS',
  CONTINENT:'Continent',
  REGION_PURCHASES: 'Region Purchases',
  PRODUCT_COMPARISON: 'Product Comparison',
  REQUEST_COMPARISON: 'Request Comparison',
  REGION:'Region',
  REPORTS:'Reports',
  CONTRACT:'Contract',
  VESSEL_PURCHASES:'Vessel Purchases',
  VESSEL_TYPE:'Vessel Type',
  BUYER_PURCHASES: 'Buyer Purchases',
  ORDER_ITEMS_EVALUATION: 'Order Items Evaluation',
  UPLOAD_INVOICE_DELIVERY_NOTE: 'Upload Invoice - Delivery Note',
  DASHBOARDS:'Dashboards',
  RATED_PRODUCTS:'Rated Products',
  PRICE_LISTS: 'Price Lists',
  PACKAGE: 'Package',
  VESSEL_SIZE: 'Vessel Size',
  CLEAR_CACHE: 'Clear Cache',
  PRODUCT_EQUIVALENTS: 'Product Equivalents',
  PRODUCT_DICTIONARIES: 'Product Dictionaries',
  PORT_DICTIONARIES: 'Port Dictionaries',
  COUNTRY_DICTIONARIES: 'Country Dictionaries',
  UNIT_DICTIONARIES: 'Unit Dictionaries',
  DICTIONARIES: 'Dictionaries',
  REGIONS: "Regions",
  PORT_REGIONS: "Port Regions",
  COUNTRY_REGIONS: "Country Regions",
  COMPANY_USER: "Company User"
};

export default en;
