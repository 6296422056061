import httpService from './httpService';

const getAllCompanies = (criteria = {}) => {
  if (!criteria.search) {
    delete criteria.search;
  }

  return httpService.FetchCompanyList(criteria);
};

const getAllCompaniesInfo = (criteria = {}) => {
  if (!criteria.search) {
    delete criteria.search;
  }
  return httpService.FetchCompanyInfoList(criteria);
};

const getCompanyById = (criteria = {}) => {
  return httpService.FetchCompany(criteria);
};

const deleteCompany = (id) => {
  return httpService.DeleteCompany(id);
};

const editCompany = (id, data) => {
  return httpService.UpdateCompany({}, data);
};

const addCompany = (data) => {
  return httpService.CreateCompany({}, data);
};

const getBuyerCompanies = (criteria = {}) => {
  if (!criteria.search) {
    delete criteria.search;
  }

  return httpService.FetchBuyers(criteria);
};

const getAllCompanyUsers = (criteria = {}) => {
  if (!criteria.search) {
    delete criteria.search;
  }

  return httpService.FetchCompanyUsers(criteria);
};

const getSupplierCompanies = (criteria = {}) => {
  if (!criteria.search) {
    delete criteria.search;
  }

  return httpService.FetchSuppliers(criteria);
};

const getAllSuppliersInfo = (criteria = {}) => {
  if (!criteria.search) {
    delete criteria.search;
  }
  return httpService.FetchSuppliersInfoList(criteria);
};

const getCompanyPreferences = (criteria = {}) => {
  return httpService.FetchCompanyPreferences(criteria);
};

const deleteCompanyPreference = (id) => {
  return httpService.DeleteCompanyPreference(id);
};

const editCompanyPreference = (id, data) => {
  
 return httpService.UpdateCompanyPreference({  }, data);
};

const addCompanyPreference = (id, data) => {
  return httpService.CreateCompanyPreference({} ,data);
};

const importBuyerInvoices = (id, data) => {
  
  let fd = new FormData();

  for (let i = 0; i < data.length; i++) {
    fd.append('files', data[i])
  };

  return httpService.UploadBuyerInvoices(id, fd,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
};



const importSupplierInvoices = (id, data) => {
  
  let fd = new FormData();

  for (let i = 0; i < data.length; i++) {
    fd.append('files', data[i])
  };

  return httpService.UploadSupplierInvoices({}, fd,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
};

const importSupplierPriceLists = (from, to, id, data) => {
  
  let fd = new FormData();

  for (let i = 0; i < data.length; i++) {
    fd.append('files', data[i]);
    fd.append('from_date', from);
    fd.append('to_date', to);
  };

  return httpService.UploadSupplierPriceLists(id, fd,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
};

const clearCompanyCache = (criteria = {}) => {
  return httpService.ClearCompanyCache({},criteria);
};



export {
  getAllCompanies,
  getAllCompaniesInfo,
  deleteCompany,
  addCompany,
  editCompany,
  getCompanyById,
  getBuyerCompanies,
  getSupplierCompanies,
  getAllSuppliersInfo,
  getCompanyPreferences,
  deleteCompanyPreference,
  editCompanyPreference,
  addCompanyPreference,
  importBuyerInvoices,
  importSupplierPriceLists,
  importSupplierInvoices,
  clearCompanyCache,
  getAllCompanyUsers
};
