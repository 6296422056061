import React, { useCallback, useEffect } from 'react';
import t from '../../helpers/languages';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { debounce } from "lodash";
import { getAllCompanyUsers } from '../../services/companiesService';
export default function FilterUsers({ user: initialUser, getSelectedUser, company = null, disabled = false }) {

  const [user, setUser] = React.useState(initialUser && initialUser.id !== null && !disabled ? initialUser : 0);
  const [users, setUsers] = React.useState(initialUser && initialUser.id !== null && !disabled ? [initialUser] : []);
  const [openUser, setOpenUser] = React.useState(false);
  const [search, setSearch] = React.useState('');

  useEffect(function () {
    disabled && setUser(0);
    disabled && setUsers([]);
  }, [disabled]);

  const handleInputChange = (e, fetchType) => {
    const value = e && e.target && e.target.value;
    if (fetchType === "user" && value && value.length >= 3 ) {
      setSearch(value);
      debounceFn(value);
    };
  };

  const handleDebounceFn = (val) => {
    if (company) {
      userSearch({
        company: val,
        companyId: company.id
      })
    } else {
      userSearch({
        company: val
      })
    }
  };
  const debounceFn = useCallback(debounce(handleDebounceFn, 800), [company]);

  const randomNumber = (min, max) => {
    return Math.random() * (max - min) + min;
  }

  const onUserSelectionChange = (event, values) => {

    setUser(values ? values : '');
    const selectedUser = {
      user: values ? values : null, // return null when the value is empty string
    };

    getSelectedUser(selectedUser);
  };

  const userSearch = (criteria) => {

    getAllCompanyUsers(criteria).then((res) => {
      setUsers(res.data);
    }).catch((error) => {
    })
  };

  return (
    <div>
      <Autocomplete
        id={"find-user" + randomNumber(0, 999)}
        fullWidth
        open={openUser}
        disabled={disabled}
        onOpen={() => {
          setOpenUser(true);
        }}
        onClose={() => {
          setOpenUser(false);
        }}
        onInputChange={e => handleInputChange(e, 'user')}
        onChange={onUserSelectionChange}
        value={users.find(v => v.id === user.id) || null}
        isOptionEqualToValue={(option, value) => (option.id === value.id)}
        getOptionLabel={(option) => (option.userName !== null ? option.userName : '')}
        options={users !== null ? users : null}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('COMPANY_USER')}
            variant="outlined"
            InputProps={{
              ...params.InputProps
            }}
          />
        )}
      />
    </div>
  );
}