import React, { useState,Fragment,  } from 'react';
import FormControl from '@mui/material/FormControl';
import t from '../../helpers/languages';
import { Grid } from "@mui/material";
import FilterPortsMultiple from "../filterPortsMultiple";
import FilterContractsMultiple from "../filterContractsMultiple";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import Button from '@mui/material/Button';
// import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import dateToString from '../../helpers/dateToString';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import './_tableFilterRequestComparison.scss';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing('1px'),

  },
  selectEmpty: {
    marginTop: theme.spacing('2px'),
  },
}));

export const TableFilterRequestComparison = ({
  portFirst: initialPortFirst,
  ports: initialPort,
  contracts: initialContract,
  searchDate: initialSearchDate,
  getFilterData
}) => {

  const classes = useStyles();
  const [portFirst, setPortFirst] = useState(initialPortFirst);
  const [port, setPort] = useState(initialPort);
  const [contract, setContract] = useState(initialContract);
  const [searchDate, setSearchDate] = useState(initialSearchDate ? moment.utc(initialSearchDate) : null);

  const onDateFromChange = (date) => {
    setSearchDate(date);
    setContract([]);
    if (moment(date).isValid() && !isNaN(date)&&date !== null) {
      const filterData = {
        searchDate: dateToString(date) + 'T00:00:00Z',
        contract: [],
        portFirst: portFirst,
        port: port,
      };

      getFilterData(filterData);
    } else if (date === null) {
      const filterData = {
        searchDate: date,
        contract: [],
        portFirst: portFirst,
        port: port,
      };
      getFilterData(filterData);
    }


  };

  const selectedPort = (data) => {
    setPort(data.port);
    const filterData = {
      port: data.port,
      portFirst: portFirst,
      contract: contract,
      searchDate: searchDate,
    };

    getFilterData(filterData);
  }



  const selectedContract = (data) => {

    setContract(data.contract);
    const filterData = {
      contract: data.contract,
      portFirst: portFirst,
      port: port,
      searchDate: searchDate,
    };

    getFilterData(filterData);
  }

  const handleChange = (e, values) => {

    setPortFirst(e.target.checked);

    const filterData = {
      contract: contract,
      portFirst: e.target.checked,
      port: port,
      searchDate: searchDate,
    };

    getFilterData(filterData);
  };


  return (
    <form>
      <Grid className={"table-filter-pricelist"}
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
       
      >
        <Grid item xs={4}>
          <FormControl fullWidth variant="outlined">
            <FilterPortsMultiple
              port={port}
              getSelectedPort={selectedPort} />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth variant="outlined">
            <FilterContractsMultiple
              contract={contract}
              searchDate={searchDate?dateToString(searchDate) + 'T00:00:00Z':null}
              getSelectedContract={selectedContract} />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Grid container spacing={1}>
                <Fragment>
                  <Grid item xs={6}>
                    <DatePicker
                      id="date-picker-from"
                      componentsProps={{
                        actionBar: {
                          actions: ['clear'],
                        },
                      }}
                      minDate={new Date(2024, 1, 1)}
                      maxDate={new Date(2030, 1, 1)}
                      label={t('DATE')}
                      value={searchDate}
                      inputFormat="yyyy-MM-DD"
                      inputVariant="outlined"
                      onChange={onDateFromChange}
                      initialFocusedDate={searchDate}
                      clearable
                      renderInput={(params) =>
                        <TextField  {...params}
                          inputProps={{ ...params.inputProps, readOnly: true }}
                        />}
                    />
                  </Grid>
                </Fragment>
              </Grid>
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={1} >
          <FormControl fullWidth variant="outlined">
            <FormControlLabel control={<Checkbox
              checked={portFirst}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />} label="group by Port" />
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
}