
const tableHeadersRfidReader = [
  { id: 'rfid_serial', disablePadding: false, label: 'Serial' },
  { id: 'password', disablePadding: false, label: 'Password' },
  { id: 'user', disablePadding: false, label: 'User' },
  { id: 'vessel', disablePadding: false, label: 'Vessel' },
  { id: 'actions', disablePadding: false, label: 'Actions' },
];

// Empty RfidReader
const newRfidReader = () => {
  return {
    id: '?',
    active: null,
    vessel: {id:null},
    authAccount:  {id:null},
    authPassInput: null,
    rfidReaderSerial: null,
    rfidReaderIp: null
  }
};

export { tableHeadersRfidReader, newRfidReader };