
const tableHeaders = [

  { id: 'name', disablePadding: false, label: 'Name' },
  { id: 'catalogue', disablePadding: false, label: 'Catalogue' },
  { id: 'dateFrom', disablePadding: false, label: 'Date From' },
  { id: 'dateTo', disablePadding: false, label: 'Date To' },
  { id: 'enabled', disablePadding: false, label: 'Enabled' },
  { id: 'row-actions', disablePadding: false },
];

// Empty pricelist
const newPricelist = () => {
  return {
    id: '?',
    name: '',
    company: { name: '', id: '' },
    dateFrom: null,
    dateTo: null,
    enabled: null,
  }
};

export { tableHeaders, newPricelist };
